import React from 'react';
import Link from '/src/components/LocalizedLink';

import Layout from '../components/Layout';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import '../assets/styles/pages/terms-and-privacy-page.scss';

const ApplyThankYou = () => {
  return (
    <Layout title="Application received" description="Many thanks for your application!">
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
              Application received
            </h1>
            <div className="subsection">
              <p className="subsection__par">Many thanks for your application!</p>
              <p className="subsection__par">
                Your application has been received and we will review it shortly. This typically
                takes a few days depending on the volume of applications we receive.
              </p>
              <p className="subsection__par">
                <b>We will contact you if we’d like to progress to an in-person interview</b> (You
                don’t need to send follow up emails if you don’t hear back from us).
              </p>
              <p className="subsection__par">
                We will also keep your details on file and contact you if future teaching positions
                become available.
              </p>
              <Link to="/" className="c-btn c-btn--red mt-50">
                Return to the home page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
